import './App.css';
import React, { Component } from 'react';

class App extends React.Component
{
  constructor(props)
  {
    super(props);
    this.state = {
      showingterms: false,
      page: "Home"
    }
  }

  navbar() {
    return (
      <div className="Card-Navbar">
        <u onClick = {() => this.setState({page: "Home"})}>Homepage</u> •&nbsp;
        <u onClick = {() => this.setState({page: "Mission"})}>Mission Statement</u> •&nbsp;
        <u onClick = {() => this.setState({page: "Story"})}>My Story</u> •&nbsp;
        <u onClick = {() => this.setState({page: "Services"})}>My Services</u> •&nbsp;
        <u onClick = {() => this.setState({page: "Downloads"})}>Downloads</u>
      </div>
    )
  }

  TNCBackBar() {
    return (
      <div className="Card-Navbar"><u onClick={() => this.setState({showingterms: false, page: "Home"})}>Back to Homepage</u></div>
    )
  }

  currentpage() {
    switch(this.state.page)
    {
      case "Home":
        return HomePage();

      case "Mission":
        return MissionStatement();

      case "Story":
        return MyStory();

      case "Services":
        return MyServices();

      case "Downloads":
        return Downloads();

      default:
        return HomePage();
    }
  }

  //Don't forget to change cursor to pointer

  render() {
    return (
      <div className="Container">
        <div className="Title"><img className="s-logo" src="whiteoutline.png" /><div className="text-logo">IFACT</div></div>
        <div className="Subtext">Serenity - the state of being calm, peaceful, and untroubled.</div>
        <div className="Card">
            {this.state.showingterms ? this.TNCBackBar() : this.navbar()}
            {this.state.showingterms ? TNC() : this.currentpage()}
          <div className="Card-Footer">Full SIFACT website under development.</div>
        </div>
        <div className='Footer' onClick={() => this.setState({showingterms: !this.state.showingterms})}>{this.state.showingterms ? "Back" : "Terms and Conditions"}</div>
      </div>
    );
  }
}

function TNC() {
  return (
    <>
      <div className="Card-Header">Terms and Conditions</div>
      <div className="Card-Body">
        <div>
          In signing up for therapy sessions under SIFACT you are agreeing to the Terms and Conditions
          as listed below.
          <hr />
          <b>Cancellation Policy:</b>
          <br />
          Please be advised that cancellations made up to 24 hours before a scheduled appointment via email or phone call will be processed without a penalty.
          <br />
          Cancellations made 24 hours or less before an appointment will be subject to a cancellation charge equal to that of a full appointment. 
          This includes appointments where the client is unavailable.
          <br />
          If SIFACT cancels an appointment with less than 48 hours notice, a new appointment will be scheduled without penalty to the client, subject to availability.
        </div>
      </div>
    </>
  );
}

function HomePage() {
  return (
    <>
      <div className="Card-Header">Serenity in Individual, Family, And Couples Therapy</div>
      <div className="Card-Body">
        <div className="Contact">Shelley Munnee • +61 497 332 113 • shelley@sifact.com.au</div>
        <hr />
        <div className="Bio">
        I'm an experienced counsellor and I have my post-grad diploma in family and relationships therapy. I also have an extensive history in AOD (Alcohol and Other Drugs) counselling and in facilitating Family Support Group therapy, having worked at an AOD agency for 10½ years.        </div>
        <hr />
        <div className="Details">
          I offer 60 minute counselling sessions, typically $100 per session. Please feel free to send an email for any further enquiries about the cost.
          <hr />
          If you are interested in arranging a therapy session now <a href="https://docs.google.com/forms/d/e/1FAIpQLSeiMilYpQ5qH7ykCEC4R_nSZ6YW5Z3y4mVYzz9p-mDs69TU2A/viewform?usp=sf_link">
            click this link to sign up!
          </a> Once I receive your registration form, I'll contact you to answer any queries you may have and to organise your first appointment!
        </div>
      </div>
    </>
  )
}

function MissionStatement() {
  return (
    <>
      <div className="Card-Header">Why SIFACT?</div>
      <div className="Card-Body">
        <div>
        I have a passion for working with individuals, couples and families in setting the sun on the negative relationship patterns, and on intergenerational trauma. 
        <hr />
        My focus is to open up a new sunrise by working with clients in creating new, loving and connected relationship patterns that will enhance the family’s future together in establishing positive intergenerational patterns within a safe environment. 
        I believe life is challenging and we all need to walk on our journey of life with the love, support and connectedness to our family. 
        <hr />
        <div className='trinity'>
          The SIFACT Logo represents the:
          <br />
          • <b>Serenity</b> provided within the safe space of the therapy room.
          <br />
          • <b>Harmony</b> achieved within the yin yang of relationships.
          <br />
          • <b>Unity</b> established within the families circle of love.
        </div>
        </div>
      </div>
    </>
  )
}

function MyStory() {
  return (
    <>
      <div className="Card-Header">My Story</div>
      <div className="Card-Body">
        <div>
          I was largely inspired by my childhood to become a psychiatric nurse when I was 18 years old, due to my father having mental health issues. 
          Throughout my life I have also been exposed to family members who have had substance use and/or mental health issues. Looking for, and holding onto hope, helped me navigate my way through it all. 
          It became important to me to help others survive the rollercoaster ride of traumas attached to their journey.
          <hr />
          I believe my life has led me to where I am today. Opportunities arose for me to provide counselling to AOD (Alcohol and Other Drugs) clients and/or those with Mental Health issues, and later in facilitating a Family Support Group. After my PostGradDip, and creating my own business SIFACT, I now have the privilege of offering therapy to Individuals, Families, and Couples. My lived experience has given me the empathy, wisdom, understanding, and learning required to help you and your loved ones set the sun on the negative relationship patterns you may bring with you. Working together we will replace these with positive relationship patterns, giving you and your family the connectedness you all deserve. What an enriched legacy; creating a new sunrise for future generations.
          </div>
      </div>
    </>
  )
}

function MyServices() {
  return (
    <>
      <div className="Card-Header">My Services</div>
      <div className="Card-Body">
        <div>Through my educational qualifications; my experience as an Alcohol and Drug counsellor, as a Family Support Group Facilitator for those who have a loved one with substance use issues, and also my time as a Therapist providing Individual, Couples and Family Therapy; I have gained the ability to provide many eclectic choices in using a myriad of researched and refined therapeutic approaches, outlooks, techniques and tools to use in the therapy room in addressing the troublesome, turbulent and traumatic situations that clients often bring with them.
          <hr />
          I will work with you in meeting your needs in regard to weekly or fortnightly therapy sessions and also with dates and times. 
        </div>
      </div>
    </>
  )
}

function Downloads() {
  return (
    <>
      <div className="Card-Header">Downloads</div>
      <div className="Card-Body">
        <div>
          <a href="http://downloads.sifact.com.au/mental-health-exercise-benefits.pdf">Mental Health and Exercise</a> <br />
          <a href="http://downloads.sifact.com.au/mental-health-maintenance-plan.pdf">Mental Health Maintenance Plan</a> <br />
          <a href="http://downloads.sifact.com.au/Treatments-for-depression.pdf">Treatments for Depression</a> <br />
          <a href="http://downloads.sifact.com.au/Symptoms-of-depression.pdf">The Symptoms of Depression</a> <br />
          <a href="http://downloads.sifact.com.au/trauma-reactions.pdf">Common Reactions to Trauma</a> <br />
          <a href="http://downloads.sifact.com.au/Fair-Fighting-Rules.pdf">Fair Fighting Rules</a> <br />
          <a href="http://downloads.sifact.com.au/boundary-styles.pdf">Boundary Styles</a> <br />
          <a href="http://downloads.sifact.com.au/IMG-3052.JPG">Gottman Sound Relationship House</a> <br />
          <a href="http://downloads.sifact.com.au/IMG-5060.JPG">The 5 Love Languages at Home</a> <br />
          <a href="http://downloads.sifact.com.au/IMG-5059.JPG">The 5 Love Languages for Yourself</a> <br />
          <a href="http://downloads.sifact.com.au/strengths-exploration.pdf">Strengths Exploration Exercise</a> <br />
        </div>
      </div>
    </>
  )
}

export default App;